import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import React from "react"

const PrivacyPolicy = () => (
  <Layout pageInfo={{
    pageName: "privacy",
    pageTitle: "Privacy Policy",
    bannerTitle: "website-header-03.png",
  }}>
    <Seo title="Privacy Policy" />

    <section className="general-page" id="privacy-policy">
      <Container>
        <Row>
          <Col>
            <div className="section-heading">
              <h2 className="title">Algemeen</h2>
            </div>
            <div className="block">
              <p>
                Deze privacyverklaring is van toepassing op de verwerking van persoonsgegevens en is opgesteld in
                overeenstemming met de Europese Algemene Verordening Gegevensbescherming (AVG).
                Gebruiker van deze privacyverklaring, tevens verwerkingsverantwoordelijke in de zin van de AVG, is Alles
                Kids Kindercoaching, Y.W. van Seters-Bogaard, gevestigd te Steenbergen, ingeschreven bij de Kamer van
                Koophandel onder nummer 82490651.
                <br /> <br />
                U wordt geadviseerd deze privacyverklaring goed door te nemen.

              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Persoonsgegevens</h2>
            </div>

            <div className="section-heading">
              <h3 className="title">Doel van de verwerking</h3>
            </div>
            <div className="block">
              <p>
                Indien u mij uw persoonlijke contactgegevens geeft, telefonisch, per e-mail, via de website of
                anderszins, dan worden deze gegevens enkel gebruikt om in contact met u te komen en te communiceren in
                het kader van de met u overeen te komen dienstverlening. Indien geen overeenkomst tot dienstverlening
                tot stand komt worden de gegevens uiterlijk 6 maanden na ontvangst verwijderd. Uw gegevens worden niet
                gebruikt voor andere doeleinden.
              </p>
            </div>

            <div className="section-heading">
              <h3 className="title">Verwerking van persoonsgegevens</h3>
            </div>
            <div className="block">
              <p>
                Indien en overeenkomst tot dienstverlening tot stand komt zal Alles Kids Kindercoaching over meer
                persoonsgegevens beschikken dan enkel de contactgegevens. Deze persoonsgegevens worden enkel gebruikt in
                het kader van de met u overeengekomen dienstverlening. Slechts die gegevens die in dat verband nodig of
                nuttig worden geacht zullen worden verzameld.
                Uw gegevens worden niet gedeeld met derden zonder uw toestemming. Hierbij geldt dat de toestemming
                altijd weer kan worden ingetrokken.
              </p>
            </div>

            <div className="section-heading">
              <h3 className="title">Beveiliging</h3>
            </div>
            <div className="block">
              <p>
                Er wordt alles aan gedaan om ervoor te zorgen dat uw persoonsgegevens veilig zijn bij Alles Kids
                Kindercoaching. Bij de verwerking van persoonsgegevens kan gebruik worden gemaakt van een of meerdere
                verwerkers. Om uw privacy te waarborgen en de technische beveiliging te verzekeren, worden alleen
                verwerkersovereenkomsten afgesloten overeenkomstig de daaraan in de AVG gestelde eisen met de garantie
                dat uw gegevens niet buiten de Europese Unie worden verwerkt. Als verwerkingsverantwoordelijke blijft
                Alles Kids Kindercoaching verantwoordelijk.
                <br /> <br />
                Persoonsgegevens die in fysieke vorm zijn vastgelegd worden bewaard in een deugdelijke, afsluitbare
                ruimte zodat derden hier geen toegang toe hebben.
                <br /> <br />
                Uw persoonsgegevens worden niet met andere partijen gedeeld zonder uw toestemming, tenzij daar een
                administratieve of wettelijke verplichting voor geldt of er een gerechtelijk bevel voor ligt.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Bewaartermijnen</h2>
            </div>
            <div className="block">
              <p>
                Uw persoonsgegevens worden niet langer bewaard dan nodig is voor het doel van de verwerking. Alles Kids
                Kindercoaching is uiteraard wel gehouden aan de wettelijke en fiscale verplichtingen. Zo moeten van de
                Belastingdienst moeten uw factuurgegevens 7 jaar bewaard blijven en overeenkomsten binnen de Jeugdwet
                dienen 15 jaar bewaard te blijven.
                <br /> <br />
                Persoonsgegevens, zonder wettelijke bewaartermijn, die in het kader van de met uw overeengekomen
                dienstverlening zijn verzameld worden niet langer dan 2 jaar na afronding van de dienst bewaard en
                daarna verwijderd of vernietigd.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Rechten betrokkene</h2>
            </div>
            <div className="block">
              <p>
                Uw privacyrechten worden gerespecteerd door Alles Kids Kindercoaching en Alles Kids Kindercoaching zal
                eraan meewerken dat u de in de AVG aan u toegekende rechten kunt uitoefenen. Voorbeelden hiervan zijn
                het recht op inzage en correctie en verwijdering van uw persoonsgegevens. Indien u van uw rechten
                gebruikt wilt maken kunt u hierover contact opnemen met Alles Kids Kindercoaching.
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default PrivacyPolicy

